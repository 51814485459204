import { BrowserSupports, VideoSourceParser } from "assets";
import classNames from "classnames";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import queryString from "query-string";
import React, { forwardRef, useEffect } from "react";

import * as styles from "./video-player.module.scss";

const YOUTUBE_URL = /youtu(\.be)?(be\.com)?/gm;

const VideoPlayer = forwardRef(
  (
    {
      autoplay,
      centerAndEnlargeVideo,
      containerClass,
      lazyload,
      loop,
      muted,
      onAutoplayFail,
      onDurationDiscover,
      playsInline,
      src,
      title,
      ui,
      videoFade,
      vtt,
    },
    videoRef
  ) => {
    const videoLink = {
      isYT: src?.match(YOUTUBE_URL),
      src: VideoSourceParser.getVideoId(src),
      params: {
        autoplay: autoplay ? 1 : 0,
        cc_load_policy: 3,
        controls: ui ? 1 : 0,
        disablekb: muted ? 1 : 0,
        enablejsapi: 1,
        fs: 1,
        iv_load_policy: 3,
        loop: loop ? 1 : 0,
        modestbranding: 1,
        playsinline: playsInline ? 1 : 0,
        showinfo: 0,
      },
    };

    useEffect(() => {
      if (autoplay) {
        videoRef?.current?.play()?.catch(onAutoplayFail);
      }
    }, [autoplay]);

    const triggerPlayOnDataLoad = async () => {
      try {
        await videoRef?.current.play();
        onDurationDiscover(videoRef?.current?.duration * 1000);
      } catch (err) {
        onAutoplayFail();
      }
    };

    // Blocker is used to absorb mouse events for controler-less videos
    const blockerClass = classNames(styles.blocker, {
      hidden: ui,
    });

    const videoClassName = classNames({
      [styles.videoFadeOut]: videoFade,
      [styles.centerAndEnlargeVideo]: centerAndEnlargeVideo,
    });

    const captions = vtt ? <track src={vtt} default /> : undefined;

    const srcDoc = `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube-nocookie.com/embed/${
      videoLink.src
    }?${queryString.stringify(
      videoLink.params
    )}><img src=https://img.youtube.com/vi/${
      videoLink.src
    }/hqdefault.jpg alt='${title}'><span>&#x25B8;</span></a>`;

    return (
      <div className={containerClass} data-testid="player">
        {videoLink.isYT && (
          <div className={styles.embedBox}>
            <div>
              <div className={styles.videoPad} />
              <div className={blockerClass} />
            </div>
            <iframe
              allow="autoplay; encrypted-media; picture-in-picture;
            fullscreen;"
              allowFullScreen=""
              data-testid="youtube"
              loading={lazyload ? "lazy" : "eager"}
              // TODO: confirm this URL builds right even when asset json files show url as "/undefined"
              src={`https://www.youtube-nocookie.com/embed/${
                videoLink.src
              }?${queryString.stringify(videoLink.params)}`}
              srcDoc={
                lazyload && !BrowserSupports("loading-lazy-attr")
                  ? srcDoc
                  : false
              }
            />
          </div>
        )}
        {!videoLink.isYT && (
          <video
            className={videoClassName}
            crossOrigin={captions ? "anonymous" : undefined}
            data-testid="video"
            loop={loop}
            muted={muted}
            onCanPlayThrough={triggerPlayOnDataLoad}
            playsInline={playsInline}
            preload="auto"
            ref={videoRef}
            src={videoLink.src}
            autoPlay
          >
            {captions}
          </video>
        )}
      </div>
    );
  }
);

VideoPlayer.propTypes = {
  autoplay: PropTypes.bool,
  centerAndEnlargeVideo: PropTypes.bool,
  containerClass: PropTypes.string,
  lazyload: PropTypes.bool,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  onAutoplayFail: PropTypes.func,
  onDurationDiscover: PropTypes.func,
  playsInline: PropTypes.bool,
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
  ui: PropTypes.bool,
  videoFade: PropTypes.bool,
  vtt: PropTypes.string,
};

VideoPlayer.defaultProps = {
  lazyload: false,
  muted: true,
  onAutoplayFail: () => {},
  onDurationDiscover: () => {},
  ui: false,
};

VideoPlayer.displayName = "VideoPlayer";

/**
 * The GraphQL fragment for retrieving Quote data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const VideoFragment = graphql`
  fragment VideoFragment on CraftAPI_componentList_video_BlockType {
    autoplay
    id
    loop
    youtubeUrl
    video {
      url
      embeddedAsset {
        code
      }
    }
  }
`;

const convert = (videoData) => {
  let src = videoData.youtubeUrl || videoData.video[0]?.url;

  // Need to use react-loadable to load this component (see atoms/index.js)
  return (
    <VideoPlayer
      autoplay={videoData.autoplay}
      key={videoData.id}
      loop={videoData.loop}
      src={src}
    />
  );
};

export { convert, VideoPlayer as default, VideoFragment };
