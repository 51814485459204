import { Theme } from "assets";
import { DeeplinkContainer } from "atoms";
import classNames from "classnames";
import { Link } from "gatsby";
import Interweave from "interweave";
import { polyfill } from "interweave-ssr";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./formatted-text.module.scss";

// SSR fill for HTML parsing
polyfill();

// Regular expression to detect HTML content
const HTML_TEXT_REGEX = /<.*>.*<.*>/;

// Regular expression to detect if we're an internal URL for localhost or searchablemuseum
const INTERNAL_URL_REGEX =
  /.*(localhost(:\d+)?|searchablemuseum\.com)(?<path>\/.*)?/im;

const getDeepLinkTitle = (title) =>
  // <p>Reverend Ike's Ensemble </p> -> reverend-ikes-ensemble
  title
    ? title
      .replace(/<[^>]*>/g, "") // remove html tags
      .replace(/[^a-zA-Z0-9 ]/g, "") // remove non alphabetic/numeric letters
      .trim()
      .replace(/\s/g, "-") // hyphenate
      .toLowerCase()
    : "";

/**
 * Parse a plain text string, using line breaks as a delimiters for new paragraphs
 *
 * @param className       an optional classname to apply to paragraph elements
 * @param Element         the outer element type (typically a <p>)
 * @param text            the text to render
 * @returns {JSX.Element} one or more paragraph elements
 */
const renderPlainText = (className, Element, style, text, deepLink, theme) => {
  const deepLinkTitle = getDeepLinkTitle(text);
  return (text || "")
    .split("\n")
    .filter(Boolean)
    .map((paragraph, i) => (
      <Element
        className={classNames(
          styles.lightThemeLinks,
          {
            [styles.deepLinkSubject]: deepLink,
            [styles.darkThemeLinks]: Theme.DarkBackgrounds.includes(theme),
          },
          className
        )}
        data-testid={`paragraph-${i}`}
        id={deepLink && i == 0 ? deepLinkTitle : undefined}
        key={`paragraph-${i}`}
        style={style}
      >
        {paragraph}
        {deepLink && i == 0 && (
          <DeeplinkContainer ariaLabel={paragraph} title={deepLinkTitle} />
        )}
      </Element>
    ));
};

/**
 * Parses a string containing HTML text, converting to the appropriate elements
 *
 * @param className       an optional classname to apply to paragraph elements
 * @param Element         the outer element type (typically a <p>)
 * @param text            the text to render
 * @returns {JSX.Element} one or more paragraph elements
 */
const renderHTMLText = (className, Element, style, text, deepLink, theme) => {
  return (
    <Interweave
      content={text}
      transform={(node, children) => {
        if (node.tagName === "p") {
          // paragraphs represent the top-level element, we replace them with provided outer element as specified
          const deepLinkTitle = getDeepLinkTitle(text);
          return (
            <Element
              className={classNames(
                styles.lightThemeLinks,
                {
                  [styles.deepLinkSubject]: deepLink,
                  [styles.darkThemeLinks]:
                    Theme.DarkBackgrounds.includes(theme),
                },
                className
              )}
              id={deepLink ? deepLinkTitle : undefined}
              style={style}
            >
              {children}
              {deepLink && (
                <DeeplinkContainer ariaLabel={children} title={deepLinkTitle} />
              )}
            </Element>
          );
        } else if (node.tagName === "a") {
          const match = INTERNAL_URL_REGEX.exec(node.getAttribute("href"));
          if (match) {
            // Internal link, leverage Gatsby <Link>
            return (
              <Link
                className={classNames({
                  [styles.darkThemeLinks]:
                    Theme.DarkBackgrounds.includes(theme),
                })}
                to={match?.groups?.path ?? "/"}
              >
                {children}
              </Link>
            );
          }
          node.setAttribute("target", "_blank");
        }
        return undefined; //return the default interweave translated component
      }}
      noWrap
    />
  );
};

const FormattedText = ({
  className,
  outerElement,
  style,
  theme,
  text,
  deepLink,
}) =>
  (text || "").match(HTML_TEXT_REGEX)
    ? renderHTMLText(
      className,
      outerElement.type,
      style,
      text.replace("<br /></p>", "</p>"), // Redactor (craft plugin) adds br tags to the end of single line entries, so we remove them here
      deepLink,
      theme
    )
    : renderPlainText(
      className,
      outerElement.type,
      style,
      text,
      deepLink,
      theme
    );

FormattedText.propTypes = {
  className: PropTypes.string,
  deepLink: PropTypes.bool,
  outerElement: PropTypes.element,
  style: PropTypes.object,
  text: PropTypes.string,
  theme: PropTypes.string,
};

FormattedText.defaultProps = {
  outerElement: <p />,
  deepLink: false,
  theme: Theme.Light,
};

export default FormattedText;
