import { IIIFContainer, VideoPlayer } from "atoms";
import classNames from "classnames";
import { CarouselWrapper } from "molecules";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./media-carousel.module.scss";

const videoOrImage = (medium, i) => {
  if (medium.videoUrl || medium.video) {
    return (
      <VideoPlayer
        containerClass={styles.video}
        muted={false}
        // TODO: youtube patch
        src={medium?.videoUrl}
        loop
        ui
      />
    );
  }

  return <IIIFContainer count={i} image={medium.image} />;
};

const MediaCarousel = ({ media, onSelectedIndex, startIndex }) => {
  return (
    <>
      <CarouselWrapper
        fontColor={"light"} // we want to use the yellow left/right buttons
        onSelectedIndex={onSelectedIndex}
        startIndex={startIndex}
      >
        {media.map((medium, i) => (
          <div
            className={classNames("embla__slide", styles.carouselSlide, {
              [styles.videoSlide]: medium.videoUrl,
            })}
            key={i}
          >
            {videoOrImage(medium, i)}
          </div>
        ))}
      </CarouselWrapper>
    </>
  );
};

MediaCarousel.propTypes = {
  media: PropTypes.array.isRequired,
  onSelectedIndex: PropTypes.func,
  startIndex: PropTypes.number,
};

export default MediaCarousel;
