// extracted by mini-css-extract-plugin
export var absoluteContainer = "elevator-module--absoluteContainer--fZQjl";
export var centerWrapper = "elevator-module--centerWrapper--5sQYt";
export var elevator = "elevator-module--elevator--XYXjP";
export var elevatorImage = "elevator-module--elevatorImage--Xny6S";
export var floatingVideo = "elevator-module--floatingVideo--Bg1vq";
export var imageOverlay = "elevator-module--imageOverlay--DpL60";
export var imageWrapper = "elevator-module--imageWrapper--m5-o4";
export var labelText = "elevator-module--labelText--4nOEp";
export var labelTextCentury = "elevator-module--labelText--century--2+aSP";
export var labelTextDecade = "elevator-module--labelText--decade--RFMNV";
export var labelTextSmaller = "elevator-module--labelText--smaller--5tMPq";
export var levelTransitionActive = "elevator-module--levelTransitionActive--KUg7q";
export var levelTransitionEndGrow = "elevator-module--levelTransitionEndGrow--s8pZj";
export var levelTransitionEndVerticalSlide = "elevator-module--levelTransitionEndVerticalSlide--7U9oc";
export var levelTransitionFirstActive = "elevator-module--levelTransitionFirstActive--l3WE0";
export var levelTransitionFirstEndGrow = "elevator-module--levelTransitionFirstEndGrow--qv7pJ";
export var levelTransitionFirstEndVerticalSlide = "elevator-module--levelTransitionFirstEndVerticalSlide--+fcK-";
export var levelTransitionFirstGrow = "elevator-module--levelTransitionFirstGrow--Payus";
export var levelTransitionFirstVerticalSlide = "elevator-module--levelTransitionFirstVerticalSlide--vzJ-7";
export var levelTransitionNextGrow = "elevator-module--levelTransitionNextGrow--gnuBx";
export var levelTransitionNextVerticalSlide = "elevator-module--levelTransitionNextVerticalSlide--B9j-R";
export var levelTransitionPreload = "elevator-module--levelTransitionPreload--y+5Tj";
export var levelTransitionPrevGrow = "elevator-module--levelTransitionPrevGrow--tpBRa";
export var levelTransitionPrevVerticalSlide = "elevator-module--levelTransitionPrevVerticalSlide--M+FO8";
export var levelTransitionStartGrow = "elevator-module--levelTransitionStartGrow--gzQrQ";
export var levelTransitionStartVerticalSlide = "elevator-module--levelTransitionStartVerticalSlide--9WYk0";
export var skipButton = "elevator-module--skipButton--s3iIO";
export var videoClass = "elevator-module--videoClass--p2EHW";