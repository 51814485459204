import queryString from "query-string";

export const YOUTUBE_URL = /youtu(\.be)?(be\.com)?/gm;

export const getValidSource = (video) => {
  const src = video?.embeddedAsset?.url || video?.url;
  // Patch in the embed code if the url is bad
  if (src.includes("undefined")) {
    return video?.embeddedAsset?.code;
  }
  return src;
};

export const getVideoId = (src) => {
  const isYouTubeUrl = src?.match(YOUTUBE_URL);
  const isShorthandUrl = src?.includes(".be");

  // Patch for Embedded Assets extension bug
  if (src.includes("iframe")) return _patchedVideoIdExtraction(src);
  // processes "youtu.be/..."
  if (isYouTubeUrl && isShorthandUrl) return src?.split(".be/")[1];
  // processes "youtube.com/watch?v=..."
  if (isYouTubeUrl && !isShorthandUrl)
    return queryString.parse(src?.split("?")[1]).v;
  return src;
};

const _patchedVideoIdExtraction = (srcString) => {
  // gets value in <iframe src="..."/> from returned embed code (we use this
  // to build our own iframes)
  const srcInEmbedCode = /(?<=\/embed\/)[^/?]+/gm;
  return srcInEmbedCode.exec(srcString)[0];
};
