// extracted by mini-css-extract-plugin
export var backgroundCover = "video-promo-module--backgroundCover--LHp-d";
export var caption = "video-promo-module--caption--MWDyS";
export var componentMargin = "video-promo-module--componentMargin--P0r57";
export var coverImage = "video-promo-module--coverImage--8FLKn";
export var darkFont = "video-promo-module--darkFont--Y9bsI";
export var featured = "video-promo-module--featured--+GtGs";
export var featuredSummary = "video-promo-module--featuredSummary--FiEW5";
export var headline = "video-promo-module--headline--76o1t";
export var leftAlign = "video-promo-module--leftAlign--dw-H7";
export var lightFont = "video-promo-module--lightFont--u2qwq";
export var standardSummary = "video-promo-module--standardSummary--tt2KK";
export var videoPromo = "video-promo-module--videoPromo--km-fl";
export var withBackgroundImage = "video-promo-module--withBackgroundImage--0Z3Xo";