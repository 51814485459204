import {
  Display,
  flattenImageFields,
  NMAAHCPropTypes,
  Theme,
  ThemeContext,
  useWindowDimensions,
} from "assets";
import {
  ActionButton,
  ArrowOverlay,
  FormattedText,
  Metadata,
  NMAAHCModalWindow,
  TextPill,
} from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { DissectionDetailModal } from "molecules";
import { DissectionPoints } from "organisms";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import * as styles from "./dissection.module.scss";

const BlankEndPage = {
  hotspots: {
    canvas: {
      img: "<img src=\"data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7\">",
    },
    pins: [],
  },
  transcript: "",
};

const Dissection = ({
  buttonText,
  coverImage,
  text,
  promoAlignment,
  subject,
  title1,
  pages,
  frontCover,
  backCover,
}) => {
  const placeImageRight =
    promoAlignment === NMAAHCPropTypes.DissectionAlignImageToRight;

  const metadata = {
    objectName: coverImage?.objectName,
    objectNumber: coverImage?.objectNumber,
    objectTitle: coverImage?.objectTitle,
    displayTitle: coverImage?.displayTitle,
    linkField: coverImage?.linkField,
    nmaahcObject: coverImage?.nmaahcObject,
    copyright: coverImage?.copyright,
    creditLine: coverImage?.creditLine,
    subjectMedium: coverImage?.subjectMedium,
    type: "dissection",
  };

  const { theme, fontType } = useContext(ThemeContext);

  const width = useWindowDimensions().width;
  const isMobile = width > 0 && width <= parseInt(styles.mobileWidth);

  const multiplePages = pages.length > 1;
  let pagesWithPlaceholder = [...pages];
  let hasEndPage = false;
  const contentPages =
    pagesWithPlaceholder.length - (frontCover && 1) - (backCover && 1);
  if (contentPages % 2 === 1 && multiplePages) {
    // Odd number of content pages so insert the blank page as the last content page before a possible back cover
    pagesWithPlaceholder.splice(
      pages.length - (backCover ? 1 : 0),
      0,
      BlankEndPage
    );
    hasEndPage = true;
  }

  const [showDissectionModal, setShowDissectionModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDocumentTranscript, setShowDocumentTranscript] = useState(false);
  const [activePin, setActivePin] = useState({
    pin: pages?.[0]?.hotspots?.pins?.[0],
    index: 0,
  });

  // index this dissection point's ul reference
  const [pagePinRefs, setPagePinRefs] = useState([]);
  const _pagePinRefs = [];
  let arrowOverlayRefs;
  const onArrowOverlayLoad = (e) => {
    arrowOverlayRefs = e;
  };
  const onDissectionPointsLoad = (e) => {
    _pagePinRefs.push({
      ...e,
      arrowOverlayRefs,
    });
    const index = _pagePinRefs.length - 1;
    // broadcast when all the page pin refs have been set
    if (_pagePinRefs.length === pagesWithPlaceholder.length) {
      setPagePinRefs(_pagePinRefs);
    }
    return index;
  };

  const [pageTurn, setPageTurn] = useState(
    pagesWithPlaceholder.map(() => false)
  ); // boolean trigger for page turning animation for each page
  const [pageCount, setPageCount] = useState(0); // keeps count of which page the user is on
  const [flipBack, setFlipBack] = useState(false); // handles page numbers display when a user goes back and forth on same page
  const [turningThePageHeight, setTurningThePageHeight] = useState({
    height: 900,
    width: 500,
  }); //sets height for turning the page container

  const pageHeightUnit = "px"; //unit for setting turning the page height

  const [currentVisiblePageIndexs, setCurrentVisiblePageIndexs] = useState(
    frontCover ? [0] : [0, 1]
  );

  // aggregates page transcripts into one transcript if turning the page
  const aggregatePageTranscripts = (pages) => {
    let transcript = "";
    pages.forEach((page) => {
      page.documentTranscript && (transcript += page.documentTranscript);
    });
    return transcript;
  };

  const getVisiblePins = () => {
    let pins = [];
    currentVisiblePageIndexs.forEach((i) => {
      pins = pins.concat(pages[i]?.hotspots?.pins);
    });
    return pins.filter((pin) => pin);
  };

  const aggPageTranscript =
    subject === "document" && aggregatePageTranscripts(pages);
  const [isAggPageTranscript, setIsAggPageTranscript] = useState(false);

  // keeps track of which arrow the user most recently clicked
  const [arrowTracker, setArrowTracker] = useState({
    next: true,
    previous: false,
  });
  // keeps track of when arrows should be dislayed
  const [arrowDisplay, setArrowDisplay] = useState({
    hasNext: true,
    hasPrevious: false,
  });

  // sets which side of the screen the transcript modal will appear on
  const [leftSide, setLeftSide] = useState(false);

  useEffect(() => {
    setLeftSide(
      !frontCover
        ? getCurrentPageIndexByActivePin() % 2 === 1
        : getCurrentPageIndexByActivePin() % 2 === 0
    );
  }, [activePin]);

  const className = classNames("row", "middle-sm", styles.dissectionContainer);

  // Classes for alternate inner text container
  const textColClassName = classNames("col-sm-5", {
    [styles.paddingRight]: placeImageRight,
    [styles.paddingLeft]: !placeImageRight,
    [styles.paddingWithoutIntroText]: placeImageRight && !text,
  });
  const itemColClassName = classNames(styles.imageContainer, "col-sm-5", {
    "first-sm": !placeImageRight,
  });

  const onNextClick = () => {
    setShowDetailModal(false);
    setShowDocumentTranscript(false);

    // checks if the last button clicked was the back button
    if (arrowTracker.previous) {
      setFlipBack(() => false);
      setArrowTracker(() => ({
        next: true,
        previous: false,
      }));
      return;
    }

    setPageCount((prev) => (pageCount === 0 ? 1 : prev + 2));
  };

  const onPreviousClick = () => {
    setShowDetailModal(false);
    setShowDocumentTranscript(false);

    // checks if the last button clicked was the next button
    if (arrowTracker.next) {
      setFlipBack(() => true);
      setArrowTracker(() => ({
        next: false,
        previous: true,
      }));
      return;
    }

    setPageCount((prev) => (pageCount === 1 ? 0 : prev - 2));
  };

  const updateCurrentVisiblePageIndexs = () => {
    // Has to be recalculated to include end page
    let arrayOfCurrentVisiblePageIndexs = [];
    if (frontCover && pageCount === 0) {
      arrayOfCurrentVisiblePageIndexs = [0];
    } else if (frontCover && flipBack && pageCount === 1) {
      arrayOfCurrentVisiblePageIndexs = [0];
    } else if (
      backCover &&
      !flipBack &&
      pageCount >= pagesWithPlaceholder.length - 2
    ) {
      arrayOfCurrentVisiblePageIndexs = [pages.length - 1];
    } else {
      let incrementIndex = pageCount === 0 ? 0 : 1;
      if (frontCover) {
        incrementIndex -= 1;
      }
      if (flipBack) {
        let firstDigit = pageCount - 2 + incrementIndex;
        let secondDigit = pageCount - 2 + 1 + incrementIndex;
        arrayOfCurrentVisiblePageIndexs = [firstDigit, secondDigit];
      } else {
        let firstDigit = pageCount + incrementIndex;
        let secondDigit = pageCount + 1 + incrementIndex;
        arrayOfCurrentVisiblePageIndexs = [firstDigit, secondDigit];
      }
    }
    setCurrentVisiblePageIndexs(arrayOfCurrentVisiblePageIndexs);
  };

  const getPageCountBarText = () => {
    if (frontCover && currentVisiblePageIndexs[0] === 0) {
      return (showDetailModal || showDocumentTranscript) && !isMobile
        ? ["", "Front Cover"]
        : ["Front Cover"];
    } else if (
      backCover &&
      currentVisiblePageIndexs[0] ===
        pagesWithPlaceholder.length - 1 - (hasEndPage && 1)
    ) {
      return (showDetailModal || showDocumentTranscript) && !isMobile
        ? ["Back Cover", ""]
        : ["Back Cover"];
    } else {
      const leftPage = currentVisiblePageIndexs[0] + (!frontCover && 1);
      let rightPage =
        leftPage === contentPages
          ? ""
          : currentVisiblePageIndexs[1] + (!frontCover && 1);
      const totalText = " of " + contentPages;

      if ((showDetailModal || showDocumentTranscript) && !isMobile) {
        return [
          "Page " + leftPage + totalText,
          "Page " + rightPage + totalText,
        ];
      } else if (rightPage) {
        return ["Pages " + leftPage + " - " + rightPage + totalText];
      }
      return ["Page " + leftPage + totalText];
    }
  };

  // activates page turning animation for when page count increases or back clicking after moving forward and vice versa
  useEffect(() => {
    if (pageCount > 0) {
      setPageTurn(() => {
        const newArray = [...pageTurn];
        newArray[frontCover ? pageCount - 1 : pageCount] =
          !newArray[frontCover ? pageCount - 1 : pageCount];
        return newArray;
      });
    }

    updateCurrentVisiblePageIndexs();
  }, [arrowTracker, pageCount]);

  useEffect(() => {
    setArrowDisplay({
      hasNext: currentVisiblePageIndexs[0] < pagesWithPlaceholder.length - 2,
      hasPrevious: currentVisiblePageIndexs[0] > 0,
    });
    setActivePin({
      pin:
        pagesWithPlaceholder[currentVisiblePageIndexs[0]].hotspots.pins[0] ||
        (1 in currentVisiblePageIndexs &&
          pagesWithPlaceholder[currentVisiblePageIndexs[1]]?.hotspots?.pins[0]),
      index: 0,
    });
  }, [currentVisiblePageIndexs]);

  const getCurrentPageIndexByActivePin = () => {
    let pageIndex;
    pages.forEach((currentPage, currentPageIndex) => {
      currentPage?.hotspots?.pins?.forEach((pin) => {
        if (JSON.stringify(activePin.pin) === JSON.stringify(pin)) {
          pageIndex = currentPageIndex;
        }
      });
    });
    return pageIndex;
  };

  const pillStyle = Theme.DarkBackgrounds.includes(theme) ? "light" : "dark";

  return (
    <div className={className} data-testid="dissection">
      <div className={textColClassName}>
        <TextPill style={pillStyle} text={subject} withLightning />
        <FormattedText
          className={classNames(styles.title, fontType)}
          outerElement={<h2 />}
          text={title1}
          deepLink
        />
        <div className={styles.introtext} data-testid="introtext">
          <FormattedText text={text} theme={theme} />
        </div>
      </div>
      <div className={itemColClassName}>
        <GatsbyImage
          alt={coverImage?.altText}
          className={styles.imageClass}
          data-testid="image"
          image={getImage(coverImage?.imageFile)}
        />
        <div className={styles.exploreButtonContainer}>
          <ActionButton
            onClick={() => setShowDissectionModal(true)}
            screenReaderText={`: ${title1}`}
            text={
              buttonText ||
              `Explore ${subject.replace(/(^\w|\s\w)/g, (m) =>
                m.toUpperCase()
              )}`
            }
          />
        </div>
      </div>

      <NMAAHCModalWindow
        dissectionShift={!multiplePages && showDetailModal && !isMobile}
        label={title1}
        onClose={() => setShowDissectionModal(false)}
        showClose={isMobile || !showDetailModal}
        showModal={showDissectionModal}
        theme={Theme.Light}
      >
        <div
          className={classNames(styles.instructionText, {
            "center-xs": multiplePages,
          })}
          data-testid="instructions"
        >
          <div
            className={classNames(styles.columnContainer, "center-xs", {
              "hidden-desktop hidden-tablet": showDetailModal,
            })}
          >
            <h2>{title1}</h2>
            <p>
              <span className={"hidden-mobile"}>
                Select hotspots to learn more
              </span>
              {((subject === NMAAHCPropTypes.DissectionDocument &&
                pages?.[0]?.documentTranscript) ||
                aggPageTranscript) && (
                <>
                  <span className={"hidden-mobile"}> or </span>
                  <a
                    onClick={() => {
                      setIsAggPageTranscript(true);
                      setShowDetailModal(true);
                      setShowDocumentTranscript(true);
                    }}
                  >
                    Open Document Transcript
                  </a>
                </>
              )}
            </p>
          </div>
        </div>
        <div className={styles.bookWrapper}>
          <div
            className={styles.pointsWrapper}
            style={{
              minHeight: multiplePages
                ? turningThePageHeight.height + pageHeightUnit
                : "none",
              width: multiplePages
                ? turningThePageHeight.width * 2 + pageHeightUnit
                : "none",
            }}
          >
            {multiplePages && (
              <ArrowOverlay
                buttonColor={NMAAHCPropTypes.ARROW_OVERLAY_BLACK_BUTTON}
                display={Display.Show}
                hasNext={arrowDisplay.hasNext}
                hasPrevious={arrowDisplay.hasPrevious}
                onLoad={onArrowOverlayLoad}
                onNextClick={onNextClick}
                onPreviousClick={onPreviousClick}
                isDissection
              />
            )}
            {pagesWithPlaceholder.map((page, i) => {
              // first page in the book
              if (!frontCover && i === 0)
                return (
                  <div
                    className={classNames(styles.page1, {
                      [styles.multiplePages]: multiplePages,
                    })}
                    data-testid="page"
                    key={i}
                  >
                    <DissectionPoints
                      activePin={activePin}
                      canvas={page.hotspots?.canvas}
                      currentPageIndex={getCurrentPageIndexByActivePin()}
                      onLoad={(e) => onDissectionPointsLoad({ ...e, i })}
                      pageIndex={multiplePages ? i : null}
                      pagePinRefs={pagePinRefs}
                      pages={pages}
                      pins={page.hotspots?.pins}
                      setActivePin={setActivePin}
                      setShowDetailModal={setShowDetailModal}
                      setShowDocumentTranscript={setShowDocumentTranscript}
                      setTurningThePageHeight={setTurningThePageHeight}
                      subject={subject}
                      title={title1}
                      turningThePage={multiplePages}
                    />
                  </div>
                );

              // last page in the book
              if (!backCover && i === pagesWithPlaceholder.length - 1)
                return (
                  <div
                    className={classNames(styles.pageFinal, {
                      [styles.multiplePages]: multiplePages,
                    })}
                    data-testid="page"
                    key={i}
                    style={{
                      position: frontCover && "relative",
                      right: frontCover && "-25%",
                    }}
                  >
                    {hasEndPage && (
                      <div
                        className={styles.endPage}
                        dir="rtl"
                        style={{
                          minHeight:
                            turningThePageHeight.height + pageHeightUnit,
                          width: turningThePageHeight.width + pageHeightUnit,
                        }}
                      >
                        End
                      </div>
                    )}

                    <DissectionPoints
                      activePin={activePin}
                      canvas={page.hotspots?.canvas}
                      currentPageIndex={getCurrentPageIndexByActivePin()}
                      onLoad={(e) => onDissectionPointsLoad({ ...e, i })}
                      pageIndex={multiplePages ? i : null}
                      pagePinRefs={pagePinRefs}
                      pages={pages}
                      pins={page.hotspots?.pins}
                      setActivePin={setActivePin}
                      setShowDetailModal={setShowDetailModal}
                      setShowDocumentTranscript={setShowDocumentTranscript}
                      subject={subject}
                      title={title1}
                      turningThePage={multiplePages}
                      endPageMatchHeight
                    />
                  </div>
                );

              // middle pages that animate
              if ((!frontCover && i % 2 === 1) || (frontCover && i % 2 === 0)) {
                return (
                  <div
                    className={classNames(styles.pageMiddle, {
                      [styles.turnPage]: pageTurn[i],
                    })}
                    data-testid="page"
                    key={i}
                    style={{ zIndex: pagesWithPlaceholder.length - i }}
                  >
                    <div className={styles.pageFront}>
                      {hasEndPage && i + 2 === pagesWithPlaceholder.length && (
                        <div
                          className={styles.endPage}
                          style={{
                            minHeight:
                              turningThePageHeight.height + pageHeightUnit,
                            width: turningThePageHeight.width + pageHeightUnit,
                          }}
                        >
                          End
                        </div>
                      )}
                      <DissectionPoints
                        activePin={activePin}
                        canvas={page.hotspots?.canvas}
                        currentPageIndex={getCurrentPageIndexByActivePin()}
                        onLoad={(e) => onDissectionPointsLoad({ ...e, i })}
                        pageIndex={multiplePages ? i : null}
                        pagePinRefs={pagePinRefs}
                        pages={pages}
                        pins={page.hotspots?.pins}
                        setActivePin={setActivePin}
                        setShowDetailModal={setShowDetailModal}
                        setShowDocumentTranscript={setShowDocumentTranscript}
                        setTurningThePageHeight={setTurningThePageHeight}
                        subject={subject}
                        title={title1}
                        turningThePage={multiplePages}
                      />
                    </div>
                    <div className={styles.pageBack}>
                      <DissectionPoints
                        activePin={activePin}
                        canvas={pagesWithPlaceholder?.[i + 1]?.hotspots?.canvas}
                        currentPageIndex={getCurrentPageIndexByActivePin()}
                        onLoad={(e) => onDissectionPointsLoad({ ...e, i })}
                        pageIndex={multiplePages ? i + 1 : null}
                        pagePinRefs={pagePinRefs}
                        pages={pages}
                        pins={pagesWithPlaceholder?.[i + 1]?.hotspots?.pins}
                        setActivePin={setActivePin}
                        setShowDetailModal={setShowDetailModal}
                        setShowDocumentTranscript={setShowDocumentTranscript}
                        setTurningThePageHeight={setTurningThePageHeight}
                        subject={subject}
                        title={title1}
                        turningThePage={multiplePages}
                      />
                    </div>
                  </div>
                );
              }

              return;
            })}
          </div>
          {multiplePages && (
            <div
              className={styles.pageCountBarText}
              data-testid="page-count-bar"
            >
              {getPageCountBarText().map((pageCountText, i) => (
                <div className={styles.page} key={i}>
                  {pageCountText}
                </div>
              ))}
            </div>
          )}

          {(getVisiblePins().length > 0 || showDocumentTranscript) && (
            <DissectionDetailModal
              activePin={activePin}
              currentPageIndex={getCurrentPageIndexByActivePin()}
              leftSide={leftSide}
              pages={pages}
              pins={getVisiblePins()}
              setActivePin={setActivePin}
              setIsAggPageTranscript={setIsAggPageTranscript}
              setShowDetailModal={setShowDetailModal}
              setShowDocumentTranscript={setShowDocumentTranscript}
              showDetailModal={showDetailModal}
              showDocumentTranscript={showDocumentTranscript}
              title={title1}
              transcript={
                isAggPageTranscript
                  ? aggPageTranscript
                  : pages?.[getCurrentPageIndexByActivePin()]
                    ?.documentTranscript
              }
            />
          )}
          <Metadata {...metadata} />
        </div>
      </NMAAHCModalWindow>
    </div>
  );
};

Dissection.propTypes = {
  backCover: PropTypes.bool,
  buttonText: PropTypes.string,
  coverImage: NMAAHCPropTypes.Image,
  frontCover: PropTypes.bool,
  pages: PropTypes.arrayOf(NMAAHCPropTypes.TurningThePagePage),
  promoAlignment: NMAAHCPropTypes.DissectionImageAlignmentOptions,
  subject: NMAAHCPropTypes.DissectionSubject,
  text: PropTypes.string,
  title1: PropTypes.string.isRequired,
};

Dissection.defaultProps = {
  backCover: false,
  frontCover: false,
  subject: NMAAHCPropTypes.DissectionDocument,
};

const DissectionFragment = graphql`
  fragment DissectionFragment on CraftAPI_componentList_dissection_BlockType {
    id
    title1
    coverImage {
      id
      ... on CraftAPI_image_Asset {
        ...ImageMetadataFragment
      }
    }
    buttonText
    text
    promoAlignment
    subject
    frontCover
    backCover
    pages {
      ... on CraftAPI_pages_BlockType {
        documentTranscript
        hotspots {
          canvas {
            img
          }
          pins {
            x
            y
            entry {
              id
              slug
              title
              ... on CraftAPI_hotspot_documentHotspot_Entry {
                transcription
                longDescription
              }
              ... on CraftAPI_hotspot_imageHotspot_Entry {
                coverImage {
                  ... on CraftAPI_image_Asset {
                    ...ImageMetadataFragment
                  }
                }
                longDescription
                transcription
              }
            }
          }
        }
      }
    }
  }
`;

const convert = (dissectionData) => (
  <Dissection {...flattenImageFields(dissectionData)} />
);

export { convert, Dissection as default, DissectionFragment };
