import { NMAAHCPropTypes } from "assets";
import atMuseumIcon from "assets/images/at-museum-icon.svg";
import onlineIcon from "assets/images/online-only-icon.svg";
import { ActionButton, FormattedText } from "atoms";
import classNames from "classnames";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./exhibitions-card.module.scss";

const getHistoricDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return `${start.getFullYear()}-${end.getFullYear()}`;
};

const ExhibitionsCard = ({
  btnText,
  coverImage,
  exhibitCardBackground,
  exhibitCardTextColor,
  exhibitChildren,
  fontType,
  formattedTitleOverride,
  location,
  pageLink,
  presentationType,
  shortDescription,
  startDate,
  endDate,
  title,
  twoColumns,
}) => {
  const coverImageCtn = coverImage ? (
    <GatsbyImage
      alt={coverImage?.altText}
      image={getImage(coverImage?.imageFile)}
    />
  ) : (
    <p>Missing Cover Image</p>
  );

  let historicDates;
  if (startDate && endDate) {
    historicDates = getHistoricDates(startDate, endDate);
  }

  const exhibitLocation = location ? "ONLINE ONLY" : "AT MUSEUM";
  const locationIcon = location ? atMuseumIcon : onlineIcon;
  const themesOrParts =
    presentationType === NMAAHCPropTypes.EXHIBIT_TYPE_THEMED
      ? "Themes"
      : "Parts";

  const textCtn = classNames(styles.lightFont, styles.ctnText, {
    [styles.darkFont]: exhibitCardTextColor === "dark",
  });

  const storiesColumns = classNames({
    [styles.doubleColumn]: twoColumns,
  });

  return (
    <div className={styles.exhibitionsCard} data-testid="exhibitions-card">
      <div className={styles.ctnImg}>
        <span className={styles.location}>
          <img alt="Exhibit Location Icon" src={locationIcon} />
          {exhibitLocation}
        </span>
        <div className={styles.imgWrapper}>{coverImageCtn}</div>
      </div>
      <div
        className={textCtn}
        style={{
          backgroundColor: exhibitCardBackground,
        }}
      >
        <div className={styles.innerWrapper}>
          <div className={styles.ctnDescription}>
            <FormattedText
              className={classNames(fontType)}
              outerElement={<h2 />}
              text={formattedTitleOverride || title}
            />
            {historicDates && (
              <p className={styles.historicDates}>{historicDates}</p>
            )}
            <FormattedText
              className={classNames("large-font")}
              text={shortDescription}
              theme={exhibitCardTextColor}
            />
          </div>
          <div className={styles.ctnBtn}>
            <ActionButton
              screenReaderText={`. Go to ${formattedTitleOverride} exhibition`}
              text={btnText || "Enter Exhibition"}
              textPlacement="left"
              to={`/${pageLink}`}
            />
          </div>
          {exhibitChildren.length > 0 && (
            <div className={styles.highlightedStories}>
              <div className={styles.exhibitDuration}>
                <i className="icon-duration" />
                <p>{`${exhibitChildren.length} ${themesOrParts}`}</p>
              </div>
              <ul className={storiesColumns}>
                {exhibitChildren.map((pillar, i) => {
                  return (
                    <li key={i}>
                      <Link to={`/${pillar.uri}`}>{pillar.title}</Link>
                      <span>&rarr;</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ExhibitionsCard.propTypes = {
  btnText: PropTypes.string,
  coverImage: NMAAHCPropTypes.Image,
  endDate: PropTypes.number,
  exhibitCardBackground: PropTypes.string,
  exhibitCardTextColor: PropTypes.string,
  exhibitChildren: PropTypes.arrayOf(PropTypes.shape({})),
  fontType: PropTypes.string,
  formattedTitleOverride: PropTypes.string,
  location: PropTypes.bool,
  pageLink: PropTypes.string,
  presentationType: NMAAHCPropTypes.ExhibitType,
  shortDescription: PropTypes.string,
  startDate: PropTypes.number,
  title: PropTypes.string,
  twoColumns: PropTypes.bool,
};

export default ExhibitionsCard;
