import { ActionButton } from "atoms";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import React, { forwardRef, useEffect, useState } from "react";

import * as styles from "./narration.module.scss";

const Narration = forwardRef(
  ({ audioMuted, onPlayPress, setAudioMuted, showPlayButton, track }, ref) => {
    const [transcriptionHidden, setTranscriptionHidden] = useState(false);
    if (track?.audioURL) {
      useEffect(() => {
        if (ref?.current?.textTracks?.[0]) {
          if (transcriptionHidden) {
            ref.current.textTracks[0].mode = "hidden";
          } else {
            ref.current.textTracks[0].mode = "showing";
          }
        }
      }, [transcriptionHidden]);
    }

    const audioIcon = classNames({
      "icon-mute": !audioMuted,
      "icon-unmute": audioMuted,
    });

    return (
      <>
        {showPlayButton && (
          <div className={styles.playButtonContainer}>
            <ActionButton icon={"play"} onClick={onPlayPress} text={"Start"} />
          </div>
        )}
        <div className={classNames("row", "center-xs", styles.bottomBar)}>
          <div
            className={classNames("col-xs-12 col-sm-6 col-md-3", {
              hidden: !track?.vttURL,
            })}
          >
            <button
              onClick={() => setTranscriptionHidden(!transcriptionHidden)}
            >
              <i aria-hidden="true" className="icon-transcription" />
              {transcriptionHidden
                ? "Show Transcription"
                : "Hide Transcription"}
            </button>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <button onClick={() => setAudioMuted(!audioMuted)}>
              <i aria-hidden="true" className={audioIcon} />
              {audioMuted ? "Unmute Audio" : "Mute Audio"}
            </button>
          </div>
        </div>
      </>
    );
  }
);

Narration.propTypes = {
  audioMuted: PropTypes.bool,
  onPlayPress: PropTypes.func,
  setAudioMuted: PropTypes.func,
  showPlayButton: PropTypes.bool,
  track: PropTypes.shape({
    audioURL: PropTypes.string,
    vttURL: PropTypes.string,
  }).isRequired,
};

Narration.defaultProps = {
  audioMuted: false,
  onPlayPress: () => {},
  showPlayButton: false,
};

Narration.displayName = "Narration";

export default Narration;
